*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    display: flex;
    flex-direction: column;
}

body {
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

/* via https://google-webfonts-helper.herokuapp.com/fonts/roboto?subsets=latin */

/* roboto-300 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    src: url("./assets/fonts/roboto-v15-latin-300.eot"); /* IE9 Compat Modes */
    src: local("Roboto Light"), local("Roboto-Light"),
        url("./assets/fonts/roboto-v15-latin-300.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("./assets/fonts/roboto-v15-latin-300.woff2") format("woff2"),
        /* Super Modern Browsers */ url("./assets/fonts/roboto-v15-latin-300.woff") format("woff"),
        /* Modern Browsers */ url("./assets/fonts/roboto-v15-latin-300.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: url("./assets/fonts/roboto-v15-latin-regular.eot"); /* IE9 Compat Modes */
    src: local("Roboto"), local("Roboto-Regular"),
        url("./assets/fonts/roboto-v15-latin-regular.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("./assets/fonts/roboto-v15-latin-regular.woff2") format("woff2"),
        /* Super Modern Browsers */ url("./assets/fonts/roboto-v15-latin-regular.woff") format("woff"),
        /* Modern Browsers */ url("./assets/fonts/roboto-v15-latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    src: url("./assets/fonts/roboto-v15-latin-500.eot"); /* IE9 Compat Modes */
    src: local("Roboto Medium"), local("Roboto-Medium"),
        url("./assets/fonts/roboto-v15-latin-500.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("./assets/fonts/roboto-v15-latin-500.woff2") format("woff2"),
        /* Super Modern Browsers */ url("./assets/fonts/roboto-v15-latin-500.woff") format("woff"),
        /* Modern Browsers */ url("./assets/fonts/roboto-v15-latin-500.svg#Roboto") format("svg"); /* Legacy iOS */
}
